import { ContentNavItem, NavItem } from "@/types";
import { BiCategoryAlt } from "react-icons/bi";
import { GrResources } from "react-icons/gr";
import { GrCertificate } from "react-icons/gr";
import {
  MdEvent,
  MdOndemandVideo,
  MdOutlineSchool,
  MdOutlineWorkOutline,
} from "react-icons/md";
import { HiOutlineTemplate } from "react-icons/hi";
import { FaLaptopCode } from "react-icons/fa";
import { IoLibraryOutline } from "react-icons/io5";
import { FaCode } from "react-icons/fa";
import { MdOutlineAutoGraph } from "react-icons/md";
import { FaClipboardList } from "react-icons/fa";
import { TbToolsKitchen3 } from "react-icons/tb";
import { TbTruckDelivery } from "react-icons/tb";
import { IoBusinessOutline } from "react-icons/io5";
import { FaRegNewspaper } from "react-icons/fa";
import { LuClipboardList } from "react-icons/lu";
const resourceLinks: ContentNavItem[] = [
  {
    title: "Library",
    href: "/library",
    icon: IoLibraryOutline,
    description: "Library Resources for the Restaurant industry",
  },
  {
    title: "Templates",
    href: "/templates",
    icon: HiOutlineTemplate,
    description:
      "Ready to use templates and guides for the Restaurant Industry",
  },
  {
    title: "Food Handler Certification",
    href: "https://foodhandler.restaurantassociation.com",

    icon: GrCertificate,
    description: "",
  },
];
const content: ContentNavItem[] = [
  {
    title: "Tech",
    href: "/topics/tech",
    icon: FaLaptopCode,
    description:
      "Non esse est duis cillum aliqua. Adipisicing laborum laborum incididunt aute.",
  },
  {
    title: "Marketing Ideas",
    href: "/topics/marketing-ideas",
    icon: MdOutlineAutoGraph,
    description:
      "Deserunt eu pariatur magna dolore ea. Adipisicing laborum laborum incididunt aute.",
  },
  {
    title: "Operations",
    href: "/topics/operations",
    icon: LuClipboardList,
    description:
      "Adipisicing laborum laborum incididunt aute. Adipisicing laborum laborum incididunt aute.",
  },
  {
    title: "Menu Development",
    href: "/topics/menu-development",
    icon: TbToolsKitchen3,
    description:
      "Irure adipisicing nisi duis aliqua nisi sit commodo exercitation ullamco est ipsum deserunt voluptate.",
  },
  {
    title: "Delivery",
    href: "/topics/delivery",
    icon: TbTruckDelivery,
    description:
      "Ipsum ex Lorem occaecat exercitation nostrud magna consectetur proident.",
  },
  {
    title: "Franchising",
    href: "/topics/franchising",
    icon: IoBusinessOutline,
    description:
      "Officia aute sit proident dolor pariatur pariatur exercitation mollit qui id.",
  },
  {
    title: "News",
    href: "/topics/news",
    icon: FaRegNewspaper,
    description: "Culpa sit ex non ex nostrud minim voluptate.",
  },
];

export const generateNavLinks = (latestVideoSlug: string) => {
  const navigationLinks: NavItem[] = [
    {
      title: "Topics",
      content,
      icon: BiCategoryAlt,
    },
    {
      title: "Shows",
      href: `/shows/${latestVideoSlug}`,
      icon: MdOndemandVideo,
    },
    {
      title: "Training",
      href: "https://training.restaurantassociation.com",
      icon: MdOutlineSchool,
    },
    {
      title: "Events",
      href: "https://webinar.restaurantassociation.com",
      icon: MdEvent,
    },
    {
      title: "Jobs",
      href: "https://jobs.restaurantassociation.com",
      icon: MdOutlineWorkOutline,
    },

    {
      title: "Resources",
      content: resourceLinks,
      icon: GrResources,
    },
  ];
  return navigationLinks;
};
