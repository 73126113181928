import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { IoChevronDown } from "react-icons/io5";

import { cn } from "@/utils/helpers/utils";
import { ContentNavItem } from "@/types";
import { HiPhone, HiPlayCircle, HiRectangleGroup } from "react-icons/hi2";
const solutions = [
  {
    name: "Blog",
    description: "Learn about tips, product updates and company culture",
    href: "#",
  },
  {
    name: "Help center",
    description:
      "Get all of your questions answered in our forums of contact support",
    href: "#",
  },
  {
    name: "Guides",
    description: "Learn how to maximize our platform to get the most out of it",
    href: "#",
  },
  {
    name: "Events",
    description:
      "Check out webinars with experts and learn about our annual conference",
    href: "#",
  },
  {
    name: "Security",
    description: "Understand how we take your privacy seriously",
    href: "#",
  },
];
const callsToAction = [
  { name: "Watch demo", href: "#", icon: HiPlayCircle },
  { name: "Contact sales", href: "#", icon: HiPhone },
  { name: "View all products", href: "#", icon: HiRectangleGroup },
];
export default function FlyoutMenu({
  links,
  title,

  gridStyle,
  itemStyle,
  children,
  panelClass,
}: {
  links: ContentNavItem[];
  title: string;
  gridStyle?: string;
  itemStyle?: string;
  children?: React.ReactNode;
  panelClass?: string;
}) {
  return (
    <Popover className=" overflow-hidden ">
      <div className="bg-white ">
        <div className="mx-auto max-w-7xl ">
          <Popover.Button
            className="relative inline-flex items-center gap-x-1  text-base  font-semibold text-gray-900 active:text-brand-black"
            aria-label="toggle popover"
          >
            {title}
            {/* <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> */}
            <IoChevronDown
              className="transition-transform ui-open:rotate-90 ui-open:transform"
              aria-hidden="true"
            />
          </Popover.Button>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className={cn(
            "absolute left-0 right-0 top-0 -z-50 overflow-hidden bg-white pt-16 shadow-lg ring-1 ring-gray-900/5",
            panelClass,
          )}
          style={{
            zIndex: -1000,
          }}
        >
          <Popover.Overlay className="fixed inset-0" />

          <div
            className={cn(
              " mx-auto  flex max-w-7xl items-start justify-center gap-2 overflow-hidden py-8",
              gridStyle,
            )}
          >
            {!children
              ? links.map((item, index) => (
                  <div
                    key={item.title}
                    className={cn(
                      `    group  relative flex w-36 grow items-center gap-6 rounded-lg bg-white p-3 text-center text-sm leading-6 hover:bg-gray-50 sm:flex-col sm:p-6`,
                      links.length < 4 && index === 0 ? " lg:col-start-2" : "",
                      itemStyle,
                    )}
                  >
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <item.icon
                        className="h-6 w-6 text-gray-600 group-hover:text-brandOrange"
                        aria-hidden="true"
                      />
                    </div>
                    <div>
                      <a
                        href={item.href}
                        className=" font-semibold text-gray-900"
                      >
                        {item.title}
                        <span className="absolute inset-0" />
                      </a>
                      {/* <p className="mt-1 text-gray-600">{item?.description}</p> */}
                    </div>
                  </div>
                ))
              : children}
          </div>

          {/* <div className="bg-gray-50">
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="grid grid-cols-1 divide-y divide-gray-900/5 sm:grid-cols-3 sm:divide-x sm:divide-y-0 sm:border-x sm:border-gray-900/5">
                {callsToAction.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="flex items-center gap-x-2.5 p-3 px-6 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 sm:justify-center sm:px-0"
                  >
                    <item.icon
                      className="h-5 w-5 flex-none text-gray-400"
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div> */}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
