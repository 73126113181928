"use client";
import SearchBar from "@/components/ui/Search/SearchBar";

import { User } from "@/services/api/queries";
import { useAuth } from "@/services/api/useAuth";
import { logout, setAuthToken } from "@/services/redux/authSlice";
import { useAppDispatch } from "@/services/redux/hooks";
import { TokenStore } from "@/types/auth";
import { cn } from "@/utils/helpers/utils";
import { Popover, Transition } from "@headlessui/react";
import { useLocalStorage } from "@uidotdev/usehooks";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { Fragment, useEffect } from "react";
import { CiLogout } from "react-icons/ci";
import { IoChevronDown } from "react-icons/io5";
import { match } from "ts-pattern";
import OptimizedImage from "../Image/OptimizedImage";
import FlyoutMenu from "./flyoutMenu";
import { generateNavLinks } from "@/lib/navigation-links";

// const hook = dynamic(() => Promise.resolve(import("@uidotdev/usehooks")), {
//   ssr: false,
// });

// const { useLocalStorage } = hook;
function DesktopNavBar({ token, latestVideoSlug }: any) {
  const navigationLinks = generateNavLinks(latestVideoSlug);
  const [user, saveUser] = useLocalStorage<User>("user", null);
  const [tokens, saveTokens] = useLocalStorage<TokenStore>("tokens", {
    training_session: { token: "", exp: new Date() },
    jobs_session: { token: "", exp: new Date() },
  });

  // }
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (token) {
      dispatch(setAuthToken(token.value));
    }
  }, []);
  return (
    <>
      <nav
        className={`  sticky z-50   w-full bg-white px-4 py-6 `}
        style={{
          boxShadow: "0 0 8px 6px #78788c33",
          position: "fixed",
          top: 0,
          zIndex: 100,
        }}
      >
        <div className="   mx-auto  flex max-w-[75rem]">
          <div className=" flex flex-1  items-center justify-between gap-4 text-[#0a0a0a]">
            <Link
              aria-label="Navigate to home page"
              href={"/"}
              className="relative h-[50px] w-[180px]"
            >
              <OptimizedImage
                src={`/_logos/logo-restaurant-association-horizontal-color.webp`}
                alt="logo"
                className="h-auto"
                style={{ objectFit: "contain" }}
                priority
                // fill
                width={180}
                height={50}
                preload
                cloudfront={true}
              />
            </Link>

            <ol className=" flex gap-4 text-[.875rem] ">
              {navigationLinks.map((link) => {
                const linkElement = match(link.title)
                  .with("Training", () => (
                    <Link
                      key={`${link.title}_desktop_nav`}
                      aria-label={`Navigate to ${link.title} page`}
                      href={`${link.href}?session=${link.title === "Training" ? tokens.training_session.token : tokens.jobs_session.token}`}
                      className=" text-base font-semibold active:text-brand-black"
                    >
                      {link.title}
                    </Link>
                  ))
                  .with("Jobs", () => (
                    <Link
                      key={`${link.title}_desktop_nav`}
                      aria-label={`Navigate to ${link.title} page`}
                      href={`${link.href}?session=${link.title === "Training" ? tokens.training_session.token : tokens.jobs_session.token}`}
                      className=" text-base font-semibold active:text-brand-black"
                    >
                      {link.title}
                    </Link>
                  ))
                  .with("Topics", () => (
                    <FlyoutMenu
                      key={`${link.title}_desktop_nav`}
                      links={link?.content ?? []}
                      title={link.title}
                    />
                  ))
                  .with("Resources", () => (
                    <FlyoutMenu
                      key={`${link.title}_desktop_nav`}
                      links={link?.content ?? []}
                      title={link.title}
                      gridStyle="justify-center gap-8 "
                      itemStyle="grow-0"
                    />
                  ))
                  .otherwise(() => (
                    <Link
                      key={`${link.title}_desktop_nav`}
                      aria-label={`Navigate to ${link.title} page`}
                      href={link.href ?? ""}
                      className="text-base font-semibold active:text-brand-black"
                    >
                      {link.title}
                    </Link>
                  ));

                return linkElement;
              })}
            </ol>

            <div className="   relative flex min-w-[180px] items-center justify-end gap-2">
              <SearchBar toggle={() => {}} />
              {token && user ? (
                <UserTile user={user} />
              ) : (
                <Link href={"/unified/login"} className={`font-semibold`}>
                  Login
                </Link>
              )}
            </div>
          </div>
        </div>
      </nav>

      {/* <div className="h-[98px]" /> */}
      {/* </header> */}
    </>
  );
}

export default DesktopNavBar;

function UserTile({ user }: { user: User }) {
  const { logout: fetchLogout } = useAuth();
  const router = useRouter();
  const dispatch = useAppDispatch();

  return (
    <Popover className="  overflow-hidden">
      <div className="bg-transparent bg-white">
        <div className="mx-auto max-w-7xl ">
          <Popover.Button className="relative inline-flex items-center gap-x-1  text-base  font-semibold text-gray-900 active:text-brand-black">
            {`${user?.firstName ?? ""} ${user?.lastName ?? ""}` || "Logout"}
            {/* <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> */}
            <IoChevronDown
              className="transition-transform ui-open:rotate-90 ui-open:transform"
              aria-hidden="true"
            />
          </Popover.Button>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className={cn("  absolute left-0 right-0 top-0   bg-white pt-16 ")}
          style={{
            zIndex: -100,
          }}
        >
          {/* <Popover.Overlay className="fixed inset-0" /> */}

          <div
            className={cn(
              " border-top-0  mx-auto flex max-w-7xl items-center  justify-center rounded-lg px-4 py-8 shadow-lg  ",
            )}
            style={{
              boxShadow: "inset 10px 0 0 calc(1px + 0) rgb(17 24 39 / 0.05)",
            }}
          >
            <div
              className={cn(
                ` w-1/7   group  relative flex grow items-center justify-between gap-6 rounded-lg  bg-white px-2 text-center text-sm leading-6  hover:bg-gray-50 sm:flex-row`,

                // links.length < 4 && index === 0 ? " lg:col-start-2" : "",
                // itemStyle,
              )}
              onClick={async () => {
                dispatch(logout());
                await fetchLogout();

                router.refresh();
              }}
            >
              <div>
                <a className=" font-semibold text-gray-900 group-hover:bg-white">
                  {/* {item?.title} */}
                  Logout
                  <span className="absolute inset-0" />
                </a>
                {/* <p className="mt-1 text-gray-600">{item?.description}</p> */}
              </div>
              <div className="flex flex-none items-center justify-center rounded-lg bg-gray-50 p-2 group-hover:bg-white ">
                <CiLogout
                  className="h-6 w-6 text-gray-600 group-hover:text-brandOrange"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
