import { cn } from "@/utils/helpers/utils";
import Link from "next/link";
import Button, { buttonVariants } from "../Button";
import OptimizedImage from "../Image/OptimizedImage";

const content = {
  title: "Become a member of the Restaurant Association!",
  details:
    "Unlock exclusive access to webinars, events, and the latest news for FREE! ",
};

function NewsletterFull() {
  return (
    <div className="mx-auto grid max-w-[75rem] grid-cols-3 items-center gap-8 bg-[#f5f5fa]">
      <div className="col-span-3 flex flex-col gap-4 rounded-md  bg-[#f5f5fa]  px-4  py-4 text-[#323239] sm:col-span-2 sm:px-16 lg:py-8">
        <h3 className="bg-[#f5f5fa] text-2xl font-semibold leading-[1.2] before:block before:w-[80px] before:border-t-4 before:border-black before:pb-3 before:content-[''] lg:text-[2.125rem]">
          {content.title}
        </h3>
        <p className="bg-[#f5f5fa] text-base lg:text-xl">{content.details}</p>
        {/* <form action="" className="bg-[#f5f5fa]">
          <input
            type="text"
            placeholder="Work email address"
            className="input input-bordered input-md w-full rounded border-[#dddee6] bg-white text-base lg:w-1/2 "
          />
        </form> */}
        {/* <button className="btn w-full max-w-[125px] rounded border-0 bg-brand text-white lg:w-1/4">
          Sign up
        </button> */}
        {/* <Link href={"/member/join"}>
          <GradientButton className="btn  mx-auto  w-full max-w-[300px]  rounded border-0 text-white sm:mx-0">
            Sign up
          </GradientButton>
        </Link> */}

        <Link
          href={"/member/join"}
          className={cn(
            buttonVariants({
              className: " w-full  max-w-[300px] rounded  border-0 text-white",
              variant: "gradient",
              size: "xl",
              pill: false,
            }),
          )}
        >
          Sign up
        </Link>
      </div>
      <div className="relative mr-12 hidden aspect-square w-28 bg-[#f5f5fa] sm:col-span-1 sm:inline-block">
        <OptimizedImage
          src={"/_logos/logo-restaurant-association-icon-only-color.webp"}
          // fill
          sizes="10vw"
          quality={100}
          alt="Restaurant Association Logo"
          style={
            {
              // objectFit: "cover",
            }
          }
          fill
          // style={{ objectFit: "contain" }}
          // width={150}
          // height={42}
        />
      </div>
    </div>
  );
}

export default NewsletterFull;

export function NewsletterBordered() {
  return (
    <div className="mx-auto grid max-w-[75rem] grid-cols-3 items-center gap-8">
      <div className="col-span-3 flex flex-col gap-4 rounded-md  px-4  py-4  text-[#323239] lg:col-span-2 lg:px-16 lg:py-8">
        <h3 className="text-2xl font-semibold leading-[1.2] before:block before:w-[80px] before:border-t-4 before:border-brand-black before:pb-3 before:content-[''] lg:text-[2.125rem]">
          {content.title}
        </h3>
        <p className="text-base lg:text-xl">{content.details}</p>
        <form action="">
          <input
            type="text"
            placeholder="Work email address"
            className="input input-md input-bordered w-full rounded border-[#dddee6] bg-white text-base lg:w-full "
          />
        </form>
        {/* <button className="btn w-full max-w-[125px] rounded border-0 bg-brand text-white lg:w-1/4">
          Sign up
        </button> */}
        <Link
          href={"/member/join"}
          className={cn(
            buttonVariants({
              className: " w-full  max-w-[300px] rounded  border-0 text-white",
              variant: "gradient",
              size: "xl",
              pill: false,
            }),
          )}
        >
          Sign up
        </Link>
      </div>
      <div className=" relative mr-12 hidden lg:col-span-1 lg:inline-block">
        <OptimizedImage
          src={"/_logos/logo-restaurant-association-icon-only-color.webp"}
          // fill
          alt="Restaurant Association Logo"
          style={{
            objectFit: "cover",
          }}
          // style={{ objectFit: "contain" }}
          width={150}
          height={42}
        />
      </div>
    </div>
  );
}

export function NewsletterBorderedMini({
  closePopup,
  className,
  customButton,
}: {
  closePopup?: () => void;
  className?: string;
  customButton?: boolean;
}) {
  return (
    <div className="mx-auto grid max-w-[75rem] grid-cols-3 gap-8 ">
      <div
        className={cn(
          "col-span-3 flex flex-col gap-4 rounded-md  px-4  py-4  text-[#323239] lg:py-8 ",
          className,
        )}
      >
        <h3 className="text-2xl font-semibold leading-[1.2] before:block before:w-[80px] before:border-t-4 before:border-brand-black before:pb-3 before:content-[''] lg:text-[1.625rem]">
          {content.title}
        </h3>
        <p className="text-base ">{content.details}</p>
        {/* <form action="">
          <input
            type="text"
            placeholder="Work email address"
            className="input input-bordered input-md w-full rounded border-[#dddee6] bg-white text-base lg:w-full "
          />
        </form> */}
        {/* <button className="btn w-full max-w-[125px] rounded border-0  text-white lg:w-max ">
          Sign up
        </button> */}
        <Link
          href={"/member/join"}
          // onClick={closePopup ? closePopup : undefined}
        >
          {!customButton ? (
            // <GradientButton className="btn w-full  rounded border-0    text-white">
            //   Sign up
            // </GradientButton>
            <Button
              className=" w-full  rounded border-0    text-white"
              variant="gradient"
            >
              Sign up
            </Button>
          ) : (
            <Button className=" w-full  rounded border-0    bg-green-600 text-white ">
              Sign up
            </Button>
          )}
        </Link>
      </div>
    </div>
  );
}
